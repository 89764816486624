import { render, staticRenderFns } from "./manageAgentDetails.pug?vue&type=template&id=ddf93f14&scoped=true&lang=pug&"
import script from "./manageAgentDetails.ts?vue&type=script&lang=ts&"
export * from "./manageAgentDetails.ts?vue&type=script&lang=ts&"
import style0 from "./manageAgentDetails.scss?vue&type=style&index=0&id=ddf93f14&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddf93f14",
  null
  
)

export default component.exports