import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

// Base Page
import BasePage from '@/pages/Base'

// Services
import UserSettingsService from '@/services/UserSettings'
import BalanceHistoryService from '@/services/BalanceHistory'
import UserPaymentsService from '@/services/UserPayments'
import moment from 'moment'
import OrderService from '@/services/Orders'
import debounce from 'lodash/debounce'
import { toFullDate } from '@/mixins/helper'

@Component({})
export default class ManageAgent extends BasePage {
  constructor() {
    super()
  }

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'center',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Date',
      align: 'center',
      sortable: true,
      value: 'created_at'
    },
    {
      text: 'Type',
      align: 'center',
      sortable: false,
      value: 'type'
    },
    {
      text: 'Ref',
      align: 'center',
      sortable: false,
      value: 'ref'
    },
    {
      text: 'Amount',
      align: 'right',
      sortable: false,
      value: 'amount'
    },
    {
      text: 'Balance',
      align: 'right',
      sortable: false,
      value: 'balance'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  // id current user
  idUser: string | number = null

  // data user
  userDetails: any = {}

  // add balance history
  balanceDialog: boolean = false
  balanceDialogData: any = {
    date: null,
    date_formatted: null,
    temp_date: null,
    currency: null,
    amount: null,
    commission_type: {
      items: [
        {
          text: 'Increase',
          value: 1
        },
        {
          text: 'Decrease',
          value: 2
        }
      ],
      selected: null
    },
    label: {
      items: [],
      selected: null
    },
    order: {
      items: [],
      selected: null,
      loading: false,
      keyword: null
    }
  }
  idBalance: string | number = 0
  editBalance: boolean = false
  dateDialogOpen: boolean = false

  // withdraw settings
  withdrawDialog: boolean = false
  withdrawDialogData: any = {
    method: {
      items: [],
      selected: null,
      loading: false
    },
    bank_name: null,
    bank_account_name: null,
    bank_account_number: null
  }
  withdrawDetails: any = {}
  isEdit: boolean = true

  searchOrders: Function

  payoutIncluded: any = {}

  deleteModal: boolean = false

  mounted() {
    this.idUser = this.$route.params.id
    this.getUsers()
    this.searchOrders = debounce(this.getOrder, 500)
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getBalance()
  }

  @Watch('balanceDialogData.order.keyword')
  async onFilterOrder() {
    this.searchOrders()
  }

  @Watch('balanceDialogData.commission_type.selected')
  onChangeCommissionType() {
    if (this.balanceDialogData.commission_type.selected === 1) {
      this.balanceDialogData.label.items = [
        {
          text: 'Bonus',
          value: 'Bonus'
        },
        {
          text: 'Order Commission',
          value: 'Order Commission'
        },
        {
          text: 'Balance Adjustment',
          value: 'Balance Adjustment'
        }]
    } else if (this.balanceDialogData.commission_type.selected === 2) {
      this.balanceDialogData.label.items = [
        {
          text: 'Withdrawal',
          value: 'Withdrawal'
        },
        {
          text: 'Balance Adjustment',
          value: 'Balance Adjustment'
        }]
    }
  }

  // get current user
  async getUsers() {
    this.showLoading({ text: 'Loading...' })
    const opts: any = {
      params: {
        include: 'payments,codes'
      }
    }

    try {
      const response = await UserSettingsService.getOneUser(this.idUser, opts)
      const currentUser = response.data.data

      this.userDetails = currentUser.attributes

      if (this.userDetails.country_signup) {
        this.userDetails.currency = response.data.included.signupcountry[this.userDetails.country_signup].attributes.currency
      } else {
        this.userDetails.currency = 'IDR'
      }

      if (!this.userDetails.balance) {
        this.userDetails.balance = 0
      }

      if (!isEmpty(currentUser.relationships.payments)) {
        this.isEdit = true
        const idPayments = currentUser.relationships.payments[0].id
        const userPayment = await this.getUserPayment(idPayments)
        this.withdrawDetails = userPayment
      } else {
        this.isEdit = false
        this.withdrawDetails = {
          remarks: null,
          value: null,
          id_payment: null
        }
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // get user payment
  async getUserPayment(id) {
    const opts: any = {
      params: {
        include: 'user,payment'
      }
    }

    try {
      const response = await UserPaymentsService.getOneUserPayments(id, opts)
      const responseData = response.data.data
      const userPayment = responseData.attributes
      userPayment.id_payment = responseData.relationships.payment.id
      return userPayment
    } catch (error) {
      this.catchHandler(error)
    }
  }

  // get balance history
  async getBalance() {
    this.tableLoading = true
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        'filter[user.id][is]': this.idUser,
        sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy
      }
    }

    try {
      const response = await BalanceHistoryService.getBalanceHistory(opts)
      const responseData = response.data.data
      const responseMeta = response.data.meta
      this.payoutIncluded = response.data.included

      this.tableItems = []
      forEach(responseData, dataBalance => {
        const balance = dataBalance.attributes
        balance.date = moment(balance.created_at).format('MMM, DD YYYY')
        balance.order_ref = balance.order_ref ? '#' + balance.order_ref : '-'
        this.tableItems.push(balance)
      })

      this.tableTotalItems = responseMeta.pagination.total
      this.tableLoading = false

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  onSelectDateInput(event) {
    this.balanceDialogData.temp_date = event
  }

  onSelectDateCancel() {
    this.dateDialogOpen = false
  }

  onSelectDateDone() {
    if (this.balanceDialogData.temp_date) {
      this.balanceDialogData.date = this.balanceDialogData.temp_date
      this.balanceDialogData.date_formatted = this.toFullDate(
        this.balanceDialogData.temp_date
      )
    }
    this.dateDialogOpen = false
  }

  // open add balance history dialog
  openAddBalanceDialog() {
    this.editBalance = false
    this.getOrder()
    this.balanceDialogData.date = null
    this.balanceDialogData.date_formatted = null
    this.balanceDialogData.temp_date = null
    this.balanceDialogData.currency = this.userDetails.currency
    this.balanceDialogData.amount = null
    this.balanceDialogData.commission_type.selected = null
    this.balanceDialogData.label.selected = null
    this.balanceDialogData.order.selected = null
    this.balanceDialog = true
  }

  // add new balance history
  async addBalanceHistory() {
    try {
      const validationResponse = await this.$validator.validateAll('balanceDialogScope')
      if (validationResponse) {
        this.balanceDialog = false
        this.showLoading({ text: 'Creating...' })
        const local = moment().format('LTS')
        const datetime = moment(this.balanceDialogData.date + ' ' + local).format('YYYY-MM-DD HH:mm:ss')
        const payload: any = {
          user_id: this.idUser,
          commission_type: this.balanceDialogData.commission_type.selected,
          label: this.balanceDialogData.label.selected,
          currency: this.userDetails.currency,
          amount: this.balanceDialogData.amount,
          created_at: datetime
        }

        if (this.balanceDialogData.order.selected) {
          payload.order_id = this.balanceDialogData.order.selected
        }

        BalanceHistoryService.setBalanceHistory(payload).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.getBalance()
          this.getUsers()
        }).catch(error => {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 2000
          })
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // open edit balance history dialog
  openEditBalanceDialog(props) {
    this.editBalance = true
    if (props.item.order_id) {
      this.balanceDialogData.order.items = []
      this.balanceDialogData.order.items.push({
        text: this.payoutIncluded.order[props.item.order_id].attributes.order_code,
        value: this.payoutIncluded.order[props.item.order_id].attributes.id
      })
    } else {
      this.getOrder()
    }

    this.idBalance = props.item.id
    this.balanceDialogData.date = this.toDatabaseDate(props.item.created_at)
    this.balanceDialogData.date_formatted = toFullDate(props.item.created_at)
    this.balanceDialogData.temp_date = null
    this.balanceDialogData.currency = props.item.currency
    this.balanceDialogData.amount = props.item.amount
    this.balanceDialogData.commission_type.selected = props.item.commission_type
    this.balanceDialogData.label.selected = props.item.label
    this.balanceDialogData.order.selected = props.item.order_id
    this.balanceDialog = true
  }

  // edit new balance history
  async editBalanceHistory() {
    try {
      const validationResponse = await this.$validator.validateAll('balanceDialogScope')
      if (validationResponse) {
        this.balanceDialog = false
        this.showLoading({ text: 'Saving...' })
        const local = moment().format('LTS')
        const datetime = moment(this.balanceDialogData.date + ' ' + local).format('YYYY-MM-DD HH:mm:ss')
        const payload: any = {
          user_id: this.idUser,
          commission_type: this.balanceDialogData.commission_type.selected,
          label: this.balanceDialogData.label.selected,
          currency: this.userDetails.currency,
          amount: this.balanceDialogData.amount,
          created_at: datetime
        }

        if (this.balanceDialogData.order.selected) {
          payload.order_id = this.balanceDialogData.order.selected
        }

        BalanceHistoryService.putBalanceHistory(this.idBalance, payload).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.getBalance()
          this.getUsers()
        }).catch(error => {
          this.showSnackbar({
            text: error.response.data.message,
            color: 'red',
            timeout: 2000
          })
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openDeleteBalanceDialog(props) {
    this.idBalance = props.item.id
    this.deleteModal = true
  }

  deleteBalanceHistory() {
    try {
      this.showLoading({ text: 'Deleting...' })
      this.deleteModal = false
      BalanceHistoryService.delete(this.idBalance).then(response => {
        this.showSnackbar({
          text: 'Deleted Successfully!',
          color: 'green',
          timeout: 1500
        })
        this.getBalance()
        this.getUsers()
      }).catch(error => {
        this.showSnackbar({
          text: error.response.data.message,
          color: 'red',
          timeout: 2000
        })
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // open withdraw setting dialog
  openWithdrawDialog() {
    this.withdrawDialog = true
    this.withdrawDialogData.method.selected = Number(this.withdrawDetails.id_payment)
    this.withdrawDialogData.bank_account_name = this.withdrawDetails.remarks
    this.withdrawDialogData.bank_account_number = this.withdrawDetails.value
    this.getPaymentList()
  }

  async getPaymentList() {
    try {
      this.withdrawDialogData.method.loading = true
      const response = await UserPaymentsService.getPaymentPlatforms()
      const responseData = response.data.data
      forEach(responseData, dataPayment => {
        if (dataPayment.attributes.available) {
          this.withdrawDialogData.method.items.push(dataPayment.attributes)
        }
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.withdrawDialogData.method.loading = false
    }
  }

  async saveWithdrawSetting() {
    try {
      const validation = await this.$validator.validateAll('withdrawDialogScope')
      if (validation) {
        this.withdrawDialog = false
        this.showLoading({ text: 'Saving...' })
        if (this.isEdit) {
          ;
          if (this.userDetails.country_catalogs === null) {
            this.userDetails.country_catalogs = []
          }
          const payload = {
            ...this.userDetails,
            relationships: [
              {
                type: 'user_payments',
                action: 'update',
                id: this.withdrawDetails.id,
                attributes: {
                  value: this.withdrawDialogData.bank_account_number,
                  remarks: this.withdrawDialogData.bank_account_name,
                  payment_id: this.withdrawDialogData.method.selected
                }
              }
            ]
          }
          UserSettingsService.update(this.userDetails.id, payload).then(response => {
            this.showSnackbar({
              text: 'Saved Successfully!',
              color: 'green',
              timeout: 1500
            })
            this.getUsers()
          }).catch(error => {
            this.showSnackbar({
              text: error.response.data.message,
              color: 'red',
              timeout: 2000
            })
          })
        } else {
          const payload = {
            value: this.withdrawDialogData.bank_account_number,
            user_id: this.userDetails.id,
            payment_id: this.withdrawDialogData.method.selected,
            remarks: this.withdrawDialogData.bank_account_name
          }
          UserPaymentsService.setUserPayments(payload).then(response => {
            this.showSnackbar({
              text: 'Saved Successfully!',
              color: 'green',
              timeout: 1500
            })
            this.getUsers()
          }).catch(error => {
            this.showSnackbar({
              text: error.response.data.message,
              color: 'red',
              timeout: 2000
            })
          })
        }
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async getOrder() {
    try {
      this.balanceDialogData.order.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 10,
          'filter[ordered_by_user_id][is]': this.idUser
        }
      }

      if (!isEmpty(this.balanceDialogData.order.keyword)) {
        var textFilter = 'filter[order_code][like]'
        opts.params[textFilter] = this.balanceDialogData.order.keyword
      } else {
        var textFilter = 'filter[order_code][like]'
        delete opts.params[textFilter]
      }

      const response = await OrderService.getOrders(opts)
      this.balanceDialogData.order.items = []
      for (const orders of response.data) {
        const order = {
          text: orders.attributes.order_code,
          value: orders.attributes.id
        }
        this.balanceDialogData.order.items.push(order)
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.balanceDialogData.order.loading = false
    }
  }
}
